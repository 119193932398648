<template>
  <div>
    <VueDatePicker
      ref="datepicker"
      v-model="date"
      range
      :start-date="getDefaultBookingView()"
      min-range="9"
      no-disabled-range
      :disabled-dates="disabledCombined"
      :enable-time-picker="false"
      :min-date="new Date()"
      :max-date="new Date(new Date().setFullYear(new Date().getFullYear() + 2))"
      :year-range="[new Date().getFullYear(), new Date().getFullYear() + 3]"
      prevent-min-max-navigation
      ignore-time-validation
      locale="de"
      auto-apply
      :markers="markers"
      timezone="UTC"
      @open="emit('blur', true)"
      @closed="emit('blur', false)"
      @range-start="setStartDate"
      @range-end="setEndDate"
      @invalid-date="onInvalidDateClick"
      @update-month-year="moveTopBar"
      class="datepicker-container"
      :class="fixedWidth ? 'fixed-width' : 'auto-width'"
    >
      <template #trigger>
        <slot name="trigger">
          <p class="clickable-text">Add custom element here to open datepicker on click.</p>
        </slot>
      </template>
      <template #month-year="{ month, year, months, years, updateMonthYear }">
        <div ref="dragOuter" class="w-full">
          <div
            class="flex justify-between items-center pt-1 px-4 mb-2 border-b border-black-10 overflow-hidden"
          >
            <button
              @click="previousMonth(month, year, updateMonthYear)"
              class="px-1 py-2 hover:ps-0 hover:pe-2 disabled:opacity-40 disabled:hover:px-1 transition-all"
              :disabled="new Date(year, month) < new Date()"
            >
              <img src="/img/chevron-left.svg" />
            </button>
            <Transition name="fade" mode="out-in">
              <p class="text-black-50 text-xs text-center" :key="headerMessage">
                {{ $t(headerMessage) }}
              </p>
            </Transition>
            <button
              @click="nextMonth(month, year, updateMonthYear)"
              class="px-1 py-2 hover:ps-2 hover:pe-0 disabled:opacity-40 disabled:hover:px-1 transition-all"
              :disabled="
                new Date(year, month + 1) >
                new Date(new Date().setFullYear(new Date().getFullYear() + 2))
              "
            >
              <img src="/img/chevron-right.svg" />
            </button>
          </div>
          <UiDragscroll class="flex w-full overflow-auto" mode="stopPropagation">
            <div class="flex" @wheel.prevent="scrollSideways">
              <ul class="flex ps-2">
                <li
                  v-for="m in months.filter((mo: Month) => mo.value > new Date().getMonth() - 1)"
                  :key="m.value"
                  :value="m.value"
                  :data-date="`${new Date().getFullYear()}-${m.value}`"
                  :class="
                    m.value === month && year === new Date().getFullYear()
                      ? 'bg-pink border-pink'
                      : ''
                  "
                  class="rounded-s mx-1 border border-black-10 px-4 py-1"
                  @click="updateMonth($event, updateMonthYear, new Date().getFullYear())"
                >
                  {{ m.text }}
                </li>
              </ul>
              <p
                class="text-black-50 ps-4 me-4 flex items-center sticky left-0 bg-white dp__year-shadow"
              >
                {{ years.find((year: Year) => year.value === new Date().getFullYear() + 1).text }}
              </p>
              <ul class="flex">
                <li
                  v-for="m in months"
                  :key="m.value"
                  :value="m.value"
                  :data-date="`${new Date().getFullYear() + 1}-${m.value}`"
                  :class="
                    m.value === month && year === new Date().getFullYear() + 1 ? 'bg-pink' : ''
                  "
                  class="rounded-s mx-1 border border-black-10 px-4 py-1"
                  @click="updateMonth($event, updateMonthYear, new Date().getFullYear() + 1)"
                >
                  {{ m.text }}
                </li>
              </ul>
              <p
                class="text-black-50 ps-4 me-4 flex items-center sticky left-0 bg-white dp__year-shadow"
              >
                {{ years.find((year: Year) => year.value === new Date().getFullYear() + 2).text }}
              </p>
              <ul class="flex">
                <li
                  v-for="m in months.filter((mo: Month) => mo.value <= new Date().getMonth())"
                  :key="m.value"
                  :value="m.value"
                  :data-date="`${new Date().getFullYear() + 2}-${m.value}`"
                  :class="
                    m.value === month && year === new Date().getFullYear() + 2 ? 'bg-pink' : ''
                  "
                  class="rounded-s mx-1 border border-black-10 px-4 py-1"
                  @click="updateMonth($event, updateMonthYear, new Date().getFullYear() + 2)"
                >
                  {{ m.text }}
                </li>
              </ul>
            </div>
          </UiDragscroll>
        </div>
      </template>
      <template #day="{ day, date }">
        {{ day }}
      </template>
      <template #marker="{ marker, day, date }" @click.stop.prevent="">
        <span class="custom-marker"></span>
      </template>
      <template #marker-tooltip="{ tooltip }">
        <p>{{ tooltip.text }}</p>
      </template>
    </VueDatePicker>
  </div>
</template>

<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import type { DatePickerInstance, ModelValue } from '@vuepic/vue-datepicker';
import { type Month, type Year, updateMonth, nextMonth, previousMonth } from '@/utils/datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const emit = defineEmits(['blur', 'showSize']);
const router = useRouter();
const localePath = useLocalePath();
const props = defineProps<{
  disabledDates?: string[];
  fixedWidth?: boolean;
  showSize?: boolean;
}>();

const { t } = useI18n();
const headerMessage = ref('datepicker.info.start-date');
const { searchDate } = useSearch();
const date = ref(searchDate) as Ref<ModelValue>;

const setStartDate = (newDate: Date) => {
  // preventing timezone offset issues by converting to UTC
  const utcDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
  calcMinBookDates(utcDate);
  headerMessage.value = 'datepicker.info.end-date';
};
const setEndDate = () => {
  router.push(localePath({ name: 'cars' }));
  headerMessage.value = 'datepicker.info.new-date';

  minBookingDates.value = [];
};

const dragOuter = ref<HTMLElement | null>(null);
const setmonthNavOffset = (newOffset: number) => {
  if (!dragOuter.value) return;
  dragOuter.value.children[0].scrollLeft += newOffset;
};
const scrollSideways = (event: WheelEvent) => {
  setmonthNavOffset(event.deltaY);
};
type MonthInView = { month: number; year: number };
const moveTopBar = ({ month, year }: MonthInView) => {
  const selector = `[data-date="${year}-${month}"]`;
  const monthLabel = document.querySelector(selector);
  if (!monthLabel) return;
  monthLabel.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
};

const minBookingDates = ref<string[]>([]);
watch(searchDate, (newValue) => {
  if (newValue.length === 0) {
    minBookingDates.value = [];
  }
});
const calcMinBookDates = (startDate: Date) => {
  const MIN_BOOKING_DAYS = 9;
  // const milliseconds = 1000 * 60 * 60 * 24 * (MIN_BOOKING_DAYS - 1);
  const firstPossiblePastNew = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() - (MIN_BOOKING_DAYS - 1),
    startDate.getHours(),
  );
  const firstPossibleNew = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() + (MIN_BOOKING_DAYS - 1),
    startDate.getHours(),
  );
  const comparableDatePast = new Date(
    Date.UTC(
      firstPossiblePastNew.getUTCFullYear(),
      firstPossiblePastNew.getUTCMonth(),
      firstPossiblePastNew.getUTCDate(),
      0,
      0,
      0,
      0,
    ),
  );
  const comparableDateNew = new Date(
    Date.UTC(
      firstPossibleNew.getUTCFullYear(),
      firstPossibleNew.getUTCMonth(),
      firstPossibleNew.getUTCDate(),
      0,
      0,
      0,
      0,
    ),
  );
  let result = [];

  while (comparableDatePast.getTime() <= comparableDateNew.getTime()) {
    result.push(comparableDatePast.toISOString());
    comparableDatePast.setUTCDate(comparableDatePast.getUTCDate() + 1);
    comparableDatePast.setUTCHours(0);
  }

  minBookingDates.value = result;
  return result;
};

const disabledCombined = computed(() => {
  const alwaysDisabled = ref([dateToday]);
  if (new Date(dateToday).getTime() + 1000 * 60 * 60 * 24 < new Date(dateBookingFrom).getTime()) {
    alwaysDisabled.value.push(dateTomorrow);
  }
  return [...(props.disabledDates ?? []), ...alwaysDisabled.value];
});

const datepicker = ref<DatePickerInstance>(null);

function getDefaultBookingView() {
  let today = new Date(dateBookingFrom);
  while (disabledCombined.value && disabledCombined.value.includes(today.toISOString())) {
    today.setDate(today.getDate() + 1);
  }
  return today;
}

interface Markers {
  date: Date | string;
  type?: 'dot' | 'line';
  tooltip?: { text: string; color?: string }[];
  color?: string;
}

const markers = computed<Markers[]>(() => {
  if (!minBookingDates.value) return [];
  return minBookingDates.value.map((r) => ({
    date: r,
    type: 'line',
    tooltip: [
      {
        text: t('datepicker.min-duration'),
      },
    ],
  }));
});

// type UpdateMonthYear = (month: number, year: number) => void;
// type Month = {
//   text:
//     | 'Jan'
//     | 'Feb'
//     | 'Mar'
//     | 'Apr'
//     | 'May'
//     | 'Jun'
//     | 'Jul'
//     | 'Aug'
//     | 'Sep'
//     | 'Oct'
//     | 'Nov'
//     | 'Dec';
//   value: number;
// };
// type Year = {
//   value: number;
//   text: string;
// };

// const updateMonth = (
//   event: InputEvent | MouseEvent,
//   updateMonthYear: UpdateMonthYear,
//   year: number,
// ) => {
//   updateMonthYear(+(event.target as HTMLSelectElement).value, year);
// };
// const nextMonth = (month: number, year: number, updateMonthYear: UpdateMonthYear) => {
//   month++;
//   if (month > 11) {
//     month = 0;
//     year++;
//   }
//   updateMonthYear(month, year);
// };
// const previousMonth = (month: number, year: number, updateMonthYear: UpdateMonthYear) => {
//   month--;
//   if (month < 0) {
//     month = 11;
//     year--;
//   }
//   updateMonthYear(month, year);
// };
const onInvalidDateClick = (date: Date) => {
  const minBookingDatesDateString = minBookingDates.value.map((d) => new Date(d).toDateString());
  // click on minDuration marker date
  if (minBookingDatesDateString.includes(date.toDateString())) {
    headerMessage.value = 'datepicker.info.min-duration';

    return;
  }
  // date in past
  if (date <= new Date()) {
    headerMessage.value = 'datepicker.info.past';
    return;
  }
  const { $toast } = useNuxtApp();

  // date too short-term
  const dayIn12Hours = new Date(Date.now() + 1000 * 60 * 60 * 12);
  if (isSameDate(date, dayIn12Hours)) {
    headerMessage.value = 'datepicker.info.min-duration';
    $toast.warning(t('datepicker.too-early.title'), {
      description: t('datepicker.too-early.description'),
    });
    return;
  }

  // disabled-date-selected
  if (disabledCombined.value.includes(date.toISOString().replace('T23', 'T00'))) {
    headerMessage.value = 'datepicker.info.unavailable';

    $toast.warning(t('datepicker.invalid-selection'), {
      description: t('datepicker.already-booked'),
    });
    return;
  }
  headerMessage.value = 'datepicker.info.unavailable';

  // probably overlap booking between start and end date
  $toast.warning(t('datepicker.invalid-selection'), {
    description: t('datepicker.overlap-booking'),
  });
};
</script>

<style>
/* datepicker theming default variables */
/* .dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1976d2;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
} */
.dp__theme_light {
  --dp-background-color: theme('colors.white');
  --dp-text-color: theme('colors.black');
  --dp-primary-color: theme('colors.orange');
  --dp-range-between-dates-background-color: theme('colors.pink');
  --dp-hover-color: theme('colors.pink');
  --dp-menu-border-color: theme('colors.black-10');

  --dp-cell-size: 30px;
  --dp-month-year-row-height: auto;
  --dp-row-margin: 3px 0;
  --dp-font-size: 14px;
  --dp-border-color: transparent;
  --dp-border-radius: 12px;
  --dp-menu-padding: 0 0 1rem;
}
.custom-marker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  @apply bg-white/75;
}
/* if datepicker needed to become even smaller on some devices
@media screen and (min-width: 768px) and (max-width: 900px) {
  .dp__theme_light {
    --dp-cell-padding: 2px;
    --dp-cell-size: 20px;
    --dp-font-size: 12px;
  }
} */
.dp__arrow_top {
  display: none;
}
.dp__outer_menu_wrap {
  left: 0 !important; /* @tobija is there a way to disable the js alignment of this component*/
  top: 3.5rem !important ;
  max-width: calc(100vw - 32px); /* Can definitely be improved*/
}
.fixed-width .dp__outer_menu_wrap {
  width: 26rem;
}
.auto-width .dp__outer_menu_wrap {
  width: auto;
  right: 0;
}
.dp__menu {
  @apply shadow font-sans;
}
.dp__calendar_header {
  margin: 4px 0 -4px 0;
  opacity: 0.5;
}
.dp__calendar_row {
  isolation: isolate;
}
.dp__calendar_item {
  display: flex;
}
.dp__cell_inner {
  flex-grow: 1;
  position: relative;
  border: none;
}
.dp__range_start,
.dp__range_end {
  position: relative;
  background: none;
}
.dp__range_start::after,
.dp__range_end::after {
  content: '';
  background: var(--dp-primary-color);
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
}
.dp__range_start::before,
.dp__range_end::before {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: -2;
}
.dp__calendar_header_item {
  font-weight: 500;
  text-transform: uppercase;
}
.dp__range_start::before {
  background: linear-gradient(
    90deg,
    transparent 50%,
    var(--dp-range-between-dates-background-color) 50%
  );
}
.dp__range_end::before {
  background: linear-gradient(
    90deg,
    var(--dp-range-between-dates-background-color) 50%,
    transparent 50%
  );
}
.dp__range_start.dp__range_end::before {
  background: none;
}
.dp__year-shadow {
  box-shadow: 12px 0 8px 6px theme('colors.white');
}
</style>
